require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("easy-autocomplete")

// JavaScript
import '../javascripts/tempusdominus-bootstrap-4';
import '../javascripts/application';

// CSS
import '../stylesheets/tempusdominus-bootstrap-4.css';
import '../stylesheets/application_mobile.scss';

require.context('../images', true)

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.$ = $;
